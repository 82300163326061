export const List1 = [
    'Chăm sóc da mặt',
    'Trang điểm',
    'Chăm sóc cá nhân',
    'Chăm sóc cơ thể',
    'Dược mỹ phẩm',
    'Sản phẩm thiên nhiên & Khác',
    'Chăm sóc tóc và da đầu',
    'Nước hoa',
    'Dụng cụ làm đẹp',
    'Máy Massage & Thiết bị chăm sóc sức khỏe',
    'Thực phẩm chức năng',
    'Hỗ trợ tình dục',
    'Bộ sản phẩm làm đẹp'
]