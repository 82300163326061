export const ListProduct = [
    {
        id: 0,
        fee: '18000đ',
        img: "https://salt.tikicdn.com/cache/96x96/ts/product/98/91/89/b48462afa0679693490b4065727a7cc8.jpg.webp",
        thumb: "https://salt.tikicdn.com/cache/96x96/ts/product/98/91/89/b48462afa0679693490b4065727a7cc8.jpg",
        title: 'Sữa  Rửa Mặt Tạo Bọt Chiết Xuất Đất Sét Trắng Senka Perfect White Clay New 14840(120g)',
        qty: 'SL: x1',
        price: '83.000 ₫',
        img1: "https://salt.tikicdn.com/cache/64x64/ts/product/02/30/6e/147a816c04f054f776dead96b5eb3479.jpg.webp",
        thumb1: "https://salt.tikicdn.com/cache/64x64/ts/product/02/30/6e/147a816c04f054f776dead96b5eb3479.jpg",
        gift: 'Quà tặng',
        title1: '[Gift] Lưới tạo bọt Senka',
        qty1: 'SL: x1',
        price1: '0 ₫',

    }
]