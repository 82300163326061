export const ListCategory = [{
    img: 'https://salt.tikicdn.com/cache/100x100/ts/product/02/b7/8f/5e557c798d058f9829adb3f9f592611a.jpg.webp',
    name: 'Apple Iphone 13Pro Max'},
    { img: 'https://salt.tikicdn.com/cache/100x100/ts/product/99/b8/0b/058927027b73cecfb7f064b35812b1bd.jpg.webp',
    name: 'Xiaomi Redmi 10 (4GB/128GB)'},
    { img: 'https://salt.tikicdn.com/cache/100x100/ts/product/a2/61/8c/1fc8de1578cdb5cf966e7acccf61b34c.jpg.webp',
    name: 'Apple Iphone 13Pro Max'},
    { img: 'https://salt.tikicdn.com/cache/100x100/ts/product/8d/1b/e9/6c4b0e5309a8f34c8db4910e35b9f51d.jpg.webp',
    name: 'Samsung Galaxy M23 5G (6GB/128GB)'},
    {img: 'https://salt.tikicdn.com/cache/100x100/ts/product/02/b7/8f/5e557c798d058f9829adb3f9f592611a.jpg.webp',
    name: 'Apple Iphone 13Pro Max'},
        {img: 'https://salt.tikicdn.com/cache/100x100/ts/product/02/b7/8f/5e557c798d058f9829adb3f9f592611a.jpg.webp',
        name: 'Apple Iphone 13Pro Max'},
        { img: 'https://salt.tikicdn.com/cache/100x100/ts/product/99/b8/0b/058927027b73cecfb7f064b35812b1bd.jpg.webp',
        name: 'Xiaomi Redmi 10 (4GB/128GB)'},
        { img: 'https://salt.tikicdn.com/cache/100x100/ts/product/a2/61/8c/1fc8de1578cdb5cf966e7acccf61b34c.jpg.webp',
        name: 'Apple Iphone 13Pro Max'},
]