let Countries = [
  'Chọn quốc tịch',
  'Ả Rập Xê Út',
  'Campuchia',
  'Đông Timor',
  'Indonesia',
  'Lào',
  'Malaysia',
  'Myanmar',
  'Philippines',
  'Singapore',
  'Thái Lan',
  'Việt Nam',
];
export default Countries;
