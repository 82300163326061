import React from 'react'
import styles from './ProfileLeft.module.css';
function ProfileListHeader() {
  return (
    <div className={ styles.ProfileListHeader}>
        <p>Thông tin tài khoản</p>
    </div>
  )
}

export default ProfileListHeader